class Result {
    constructor(id, originTag, originCoordinate) {
        this.id = id;
        this.originTag = originTag;
        this.originCoordinate = originCoordinate;
        this.isCorrect = null
    }
}

export { Result };
